// **  Initial State
const initialState = {
  orders: [],
  ordersCustomer: [],
  customer:{},
  trackingCustomer: []
};

const ordersReducer = (state = initialState, action) => {
  switch (action.type) {
    case "FETCH_ORDERS_BY_CUSTOMER":
      return { ...state, ordersCustomer: action.payload };
    case "FETCH_ORDERS":
      return { ...state, orders: action.payload };
    case "FETCH_ORDERS_TRACKING_ADDRESSEE":
      return { ...state, trackingCustomer: action.payload };
    case "DASHBOARD_DATA":
      return {
        ...state,
        dashData: action.response,
      };
    case "FETCH_ORDERS_ERROR": {
      return { ...state, error: action.payload };
    }
    case "INIT": {
      return { ...state, loadingCalc: true, cancelOrderSuccess: false };
    }
    case "LIST_PRICE_TERMS": {
      return {
        ...state,
        listOptions: action.payload.pricesAndTerms,
        errorPriceTerms: action.payload.erro,
        loadingCalc: false,
      };
    }
    case "PRINT_OUT": {
      return {
        ...state,
        loadingPrintOut: false,
        successPrintOut: true,
      };
    }
    case "PRINT_OUT_ERROR": {
      return {
        ...state,
        loadingPrintOut: false,
        successPrintOut: false,
        errorPrintOut: action.payload,
      };
    }
    case "PRINT_OUT_INIT": {
      return {
        ...state,
        loadingPrintOut: true,
        successPrintOut: false,
        errorPrintOut: false,
      };
    }
    case "INIT_TRACK": {
      return { ...state, tracks: [], errorTrack: false };
    }
    case "FETCH_TRACK_ORDER": {
      return { ...state, tracks: action.payload };
    }
    case "FETCH_TRACK_ORDER_TAG": {
      return {
        ...state,
        tracks: action.payload?.tracks,
        order: action.payload?.order,
      };
    }
    case "FETCH_TRACK_ORDER_ERROR": {
      return { ...state, errorTrack: true };
    }
    case "CANCEL_ORDER_MOTTU": {
      return { ...state, cancelOrderSuccess: true };
    }
    case "FETCH_CUSTOMER":
      return { ...state, customer: action.payload };
    case "INIT_CHECK_DESTINATION": {
      return { ...state, sendOrders: [], errorTrack: false };
    }
    case "LIST_CHECK_DESTINATION": {
      return { ...state, sendOrders: action.payload };
    }
    case "INIT_DELETE_ORDER":
      return { ...state, deleteOrderSuccess: null, deleteOrderError: null };
    case "DELETE_ORDER_ERROR":
      return { ...state, deleteOrderError: true };
    case "DELETE_ORDER":
      const index = state.ordersCustomer.findIndex((item) => item.id === action.data.id)
      const deleteData = [...state.ordersCustomer]
      deleteData.splice(index, 1)
      return { ...state, ordersCustomer: deleteData, deleteOrderSuccess: true }
    default:
      return state;
  }
};

export default ordersReducer;
