// **  Initial State
const initialState = {
  userData: {},
  error: false,
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case "LOGIN":
      return { ...state, userData: action.payload };
    case "LOGIN_ERROR":
      return { ...state, error: action.payload };
    case "LOGOUT":
      return { ...state, userData: null };
    case "SIGN_UP":
      return { ...state, userData: action.data };
    case "INIT_AUTH":
      return { ...state, forgot: false, reset: false };
    case "FORGOT_PASSWORD":
      return { ...state, forgot: true };
    case "RESET_PASSWORD":
      return { ...state, reset: true };
    default:
      return state;
  }
};

export default authReducer;
