// **  Initial State
const initialState = {
  carriers: [],
  deleteCarrierSuccess: false,
  deleteCarrierError: false,
  deleteServiceSuccess: false,
  deleteServiceError: false
}

const carriersReducer = (state = initialState, action) => {
  switch (action.type) {
    case "FETCH_CARRIERS":
      return { ...state, carriers: action.payload }
    case "FETCH_CARRIERS_ERROR": {
      return { ...state, error: action.payload }
    }
    case "CREATE_CARRIER":
      const data = [...state.carriers]
      const carrier = action.data
      carrier.id = action.response.id
      data.push(carrier)
      return { ...state, carriers: data }
    case "UPDATE_CARRIER":
      const dataUpdate = [...state.carriers]
      const indexUpdate = state.carriers.findIndex(
        (item) => item.id === action.response
      )
      dataUpdate[indexUpdate] = action.data
      return { ...state, carriers: dataUpdate }
    case "DELETE_INIT":
      return { ...state, deleteCarrierSuccess: false, deleteCarrierError: false }
    case "DELETE_CARRIER":
      const index = state.carriers.findIndex((item) => item.id === action.data)
      const deleteData = [...state.carriers]
      deleteData.splice(index, 1)
      return { ...state, carriers: deleteData, deleteCarrierSuccess: true }
    case "CREATE_SERVICE":
      const dataCreateService = [...state.carriers]
      const carrierService = action.data
      const indexCarrierUpdate = dataCreateService.findIndex(
        (item) => item.id === action.data.idCarrier
      )
      carrierService.id = action.response.id
      dataCreateService[indexCarrierUpdate].carrierServices.push(carrierService)
      return { ...state, carriers: dataCreateService }
    case "UPDATE_SERVICE":
      const dataServiceUpdate = [...state.carriers]
      const idxCarrierUpdate = state.carriers.findIndex(
        (item) => item.id === action.data.idCarrier
      )
      const idxServiceUpdate = dataServiceUpdate[idxCarrierUpdate].carrierServices.findIndex(
        (item) => item.id === action.data.id
      )
      dataServiceUpdate[idxCarrierUpdate].carrierServices[idxServiceUpdate] = action.data
      return { ...state, carriers: dataServiceUpdate }
    case "DELETE_CARRIER_ERROR":
      return { ...state, deleteCarrierError: true }
    case "DELETE_SERVICE":
      const dataCarrierDeleteService = [...state.carriers]
      const idxCarrierDelete = state.carriers.findIndex(
        (item) => item.id === action.data.idCarrier
      )
      const idxDeleteService = dataCarrierDeleteService[idxCarrierDelete].carrierServices.findIndex(
        (item) => item.id === action.data.id
      )
      dataCarrierDeleteService[idxCarrierDelete].carrierServices.splice(idxDeleteService, 1)
      return { ...state, carriers: dataCarrierDeleteService, deleteServiceSuccess: true }
    case "DELETE_SERVICE_ERROR":
      return { ...state, deleteServiceError: true }
    default:
      return state
  }
}

export default carriersReducer
