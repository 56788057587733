// ** Redux Imports
import { combineReducers } from 'redux'

// ** Reducers Imports
import auth from './auth'
import navbar from './navbar'
import layout from './layout'
import customers from './customers'
import orders from './orders'
import carriers from './carriers'
import users from './users'
import invoice from '@src/views/invoice/store/reducer'

const rootReducer = combineReducers({
  auth,
  navbar,
  layout,
  customers,
  orders,
  carriers,
  invoice,
  users
})

export default rootReducer
