// **  Initial State
const initialState = {
  customers: [],
  carriers: [],
  services: [],
  deleteUserSuccess: false,
  deleteUserError: false,
};

const customersReducer = (state = initialState, action) => {
  switch (action.type) {
    case "FETCH_CUSTOMERS":
      return { ...state, customers: action.payload };
    case "FETCH_CUSTOMERS_ERROR": {
      return { ...state, error: action.payload };
    }
    case "CREATE_CUSTOMER":
      const data = [...state.customers];
      const customer = action.data;
      customer.id = action.response.id;
      data.push(customer);
      return { ...state, customers: data };
    case "CREATE_CUSTOMER_ERROR":
      console.log(action.response);
      return { ...state, customerCreateError: action.response?.errorMessage };
    case "UPDATE_CUSTOMER":
      debugger
      const dataUpdate = [...state.customers];
      const indexUpdate = state.customers.findIndex(
        (item) => item.id === action.response.id
      );
      dataUpdate[indexUpdate] = action.data;
      return { ...state, customers: dataUpdate };
    case "DELETE_CUSTOMER":
      return { ...state, deleteCustomerSuccess: true };
    case "DELETE_CUSTOMER_ERROR":
      return { ...state, deleteCustomerError: true };
    case "FETCH_CARRIERS":
      return { ...state, carriers: action.payload };
    case "FETCH_CUSTOMER_CARRIER_SERVICE":
      return { ...state, services: action.payload };
    case "CREATE_CUSTOMER_CARRIER_SERVICE":
      return { ...state, services: action.payload };
    case "CREATE_USER":
      const dataCustomerCreateUser = [...state.customers];
      const user = action.data;
      const indexCustomerUpdate = state.customers.findIndex(
        (item) => item.id === action.data.idCustomer
      );
      user.id = action.response.id;
      const idxUser =
        dataCustomerCreateUser[indexCustomerUpdate].users.push(user);
      return { ...state, customers: dataCustomerCreateUser };
    case "UPDATE_USER":
      const dataCustomerUpdateUser = [...state.customers];
      const idxCustomerUpdate = state.customers.findIndex(
        (item) => item.id === action.data.idCustomer
      );
      const idxUserUpdate = dataCustomerUpdateUser[
        idxCustomerUpdate
      ].users.findIndex((item) => item.id === action.data.id);
      dataCustomerUpdateUser[idxUserUpdate].users[idxUser] = action.data;
      return { ...state, customers: dataCustomerUpdateUser };
    case "DELETE_INIT":
      return {
        ...state,
        deleteUserSuccess: false,
        deleteUserError: false,
        deleteCustomerError: false,
        deleteCustomerSuccess: false,
        customerCreateError: false,
      };
    case "DELETE_USER":
      const dataCustomerDeleteUser = [...state.customers];
      const idxCustomerDelete = state.customers.findIndex(
        (item) => item.id === action.data.idCustomer
      );
      const idxDeleteUser = dataCustomerDeleteUser[
        idxCustomerDelete
      ].users.findIndex((item) => item.id === action.data.id);
      dataCustomerDeleteUser[idxCustomerDelete].users.splice(idxDeleteUser, 1);
      return {
        ...state,
        customers: dataCustomerDeleteUser,
        deleteUserSuccess: true,
      };
    case "DELETE_USER_ERROR":
      return { ...state, deleteUserError: true };
    case "CREATE_DISCOUNT":
      const dataCustomerCreateDiscount = [...state.customers];
      const discount = action.data;
      const index = state.customers.findIndex(
        (item) => item.id === action.data.idCustomer
      );
      discount.id = action.response.id;
      dataCustomerCreateDiscount[index].discounts.push(discount);
      return { ...state, customers: dataCustomerCreateDiscount };
    case "CREATE_NOTIFICATION":
      const dataCustomerCreateNotification = [...state.customers];
      const notification = action.data;
      const indexNot = state.customers.findIndex(
        (item) => item.id === action.data.idCustomer
      );
      notification.id = action.response.id;
      dataCustomerCreateNotification[indexNot].notifications.push(notification);
      return { ...state, customers: dataCustomerCreateNotification };
    default:
      return state;
  }
};

export default customersReducer;
